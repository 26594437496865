<template>
    <a-modal v-model="visible" @ok="handleOk" @cancel="handleCancel" width="700px" :footer="null" :closable="false">
      <div class="labsRss-detailModal">
        <div class="labsRss-detailModal-nav">
            <span>来源：{{item.channel.title}}</span>
            <span>
                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        <a-icon type="ellipsis" />
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item key="0" @click="toDel">
                            删除文章
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a style="margin-left:10px" :href="item.link" target="_blank">访问原网址</a>
                <a-icon type="caret-right" />
            </span>
        </div>
        <div class="labsRss-detailModal-title">{{item.title}}</div>
        <div class="labsRss-detailModal-content" v-html="item.description"></div>
      </div>
    </a-modal>
</template>

<script>
    export default {
        name:'detailModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
            }
        },
        props: {
            item: Object
        },
        created () {
            this.visible = true
        },
        methods: {
            handleOk() {},
            handleCancel() {
                this.parent.hideDetailModal()
            },
            toDel(){
                this.$store.dispatch('labsRssDeleteAction',{id:this.item.id})
                .then(res=>{
                    this.$message.success('删除成功！！')
                    this.parent.hideDetailModal(1)
                })
            }
        },
    }
</script>

<style lang="scss">
    .labsRss-detailModal{
        a{
            color: rgba(0, 0, 0, 0.65) !important;
        }
        &-nav{
            display: flex;
            justify-content: space-between;
            padding-bottom: 15px;
            border-bottom: 1px solid #e8e8e8;
        }
        &-title{
            font-size: 16px;
            font-weight: bold;
            color: #000;
            margin: 15px 0;
        }
        &-content{
            visibility: visible!important;
            img{
                max-width: 100%;
            }
            
        }
    }
</style>