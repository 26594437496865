<template>
  <a-modal title="订阅RSS" v-model="visible" :confirmLoading="confirmLoading" width="600px"
    @ok="handleOk" @cancel="handleCancel" cancelText="取消" okText="保存">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
const formData = [
  {
    name: 'rss_link',
    label: 'RSS链接',
    type: 'input',
    rules: [{ required: true, message: '请输入RSS链接!' }]
  }
]
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        created () {
            this.visible = true
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                await this.$store.dispatch('labsRssAddAction',params)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.parent.hideEditModal(1)
                })
            },
            handleCancel() {
                this.parent.hideEditModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>