<template>
    <div class="labsRss-leftModal">
        <a-list :data-source="list">
            <a-list-item slot="renderItem" slot-scope="item, index" :class="checkIndex == index? 'labsRss-leftModal-check':''" @click="toCheck(item,index)">
                <div class="labsRss-leftModal-title">
                    <div class="labsRss-leftModal-title-name">
                        <a-tooltip>
                            <template slot="title">
                            {{item.title}}
                            </template>
                            {{item.title}}
                        </a-tooltip>
                    </div>
                    <div class="labsRss-leftModal-title-count">
                        <span>({{item.count}})</span>
                        <a-dropdown placement="bottomRight" class='talkingSkills-left-item-icon'>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-icon type="more" />
                            </a>
                            <a-menu slot="overlay">
                            <a-menu-item @click="toDel(item,index)">
                                删除
                            </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
            </a-list-item>
        </a-list>
    </div>
</template>

<script>
    export default {
        name:'leftModal',
        inject: ['parent'],
        data() {
            return {
                list: [],
                loading: false,
                checkIndex: -1,
            }
        },
        props:{
            isRefresh: Number
        },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                await this.$store.dispatch('labsRssChannelAction', {})
                .then(res=>{
                    this.list = res.data
                })
            },
            toCheck(item,index){
                if(this.checkIndex != index){
                    this.checkIndex = index
                    this.parent.searchList(item)
                }else{
                    this.checkIndex = -1
                    this.parent.searchList()
                }
            },
            async toDel(item,index){
                await this.$store.dispatch('labsRssDeleteChannelAction',{channel_id:item.channel_id})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    if(this.checkIndex == index){
                        this.checkIndex = -1
                    }
                    this.parent.searchList()
                    this.getList()
                })
            }
        },
        watch: {
            isRefresh(newValue, oldValue) {
                this.getList()
            }
        },
    }
</script>

<style lang="scss" scoped>
    .labsRss-leftModal{
        &-title{
            width: 100%;
            padding: 0 10px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            &-name{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                white-space: nowrap;
            }
            &-count{
                width: 70px;
                text-align: right;
            }
        }
        &-title:hover{
            color: #00cca2;
        }
        &-check{
            color: #00cca2;
            background: #e6fff6;
        }
    }
</style>