<template>
    <div style="height:100%">
        <div class="labsRss">
            <div 
                class="labsRss-list"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading && list.length==0" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-card @click="showDetailModal(item,index)" class="labsRss-list-card" hoverable>
                            <div class="labsRss-list-nav">
                                <a href="javascript:;">{{item.title}}</a>
                                <div class="labsRss-list-nav-time">{{item.pubDate}}</div>
                            </div>
                            <div class="labsRss-list-content" v-html="item.short_description"></div>
                            <div class="labsRss-list-tag">
                                <span style="color:#ccc">{{item.channel.title}}</span>
                            </div>
                        </a-card>
                    </a-list-item>
                </a-list>
            </div>
        </div>
        <div @click="showEditModal" class="labsRss-add">
            <a-icon style="font-size:36px;color:#00cca2;cursor: pointer;" type="plus-circle" />
        </div>
        <leftModal class="labsRss-left" :isRefresh="isRefresh" />
        <detailModal v-if="isDetailModal" :item="modalData"/>
        <editModal v-if="isEditModal" :item="modalData"/>
    </div>
</template>

<script>
    import editModal from './editModal'
    import leftModal from './leftModal'
    import detailModal from './detailModal'
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:"RSS",
        data() {
            return {
                list: [],
                busy: true,
                loading: false,
                isEditModal: false,
                isDetailModal: false,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {}
                },
                modalData:{},
                delIndex:-1,
                isRefresh:1
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
            leftModal,
            detailModal,
        },
        directives: { infiniteScroll },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                this.loading = true
                await this.$store.dispatch('labsRssAction',this.searchParams)
                .then(res=>{
                    if(this.searchParams.page == 1){
                        this.busy = false
                    }
                    if(res.items.length >0){
                        this.list = [...this.list,...res.items]
                    }
                    if(res._meta.totalCount == this.list.length){
                        this.busy = true
                    }
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            async toClick(id){
                await this.$store.dispatch('labsRssClickAction',{id})
                .then(res=>{})
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            showDetailModal(item,index){
                this.toClick(item.id)
                item.description = this.replaceurl(item.description)
                this.delIndex = index
                this.modalData = item
                this.isDetailModal = true
            },
            hideDetailModal(val){
                if(val){
                    this.list.splice(this.delIndex,1)
                }
                this.isDetailModal = false
            },
            showEditModal(){
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.searchList()
                    this.isRefresh++
                }
                this.isEditModal = false
            },
            searchList(obj){
                if(obj){
                    this.searchParams.search.channel_id = obj.channel_id
                }else{
                    this.searchParams.search = {}
                }
                this.searchParams.page = 1
                this.list = []
                this.busy = true
                this.getList()
            },
            // 给a标签添加属性
            replaceurl(detail) {
                var content = detail.replace(/<a[^>]*>/gi, function(match) {
                    let matchStr = match.slice(0, 2) + ` target=_blank` + match.slice(2)
                    return matchStr
                })
                return content
            }
        },
    }
</script>

<style lang="scss" scoped>
    .labsRss{
        &-list{
            height: calc(100vh - 110px);
            overflow-y: auto;
            &-card{
                width: 800px;
                margin-left: 400px;
            }
            &-nav{
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                a{
                    color: #000;
                    font-size: 16px;
                    font-weight: bold;
                    flex: 1;
                }
                &-time{
                    width: 125px;
                    text-align: right;
                }
            }
            &-content{
                margin-top: 15px;
            }
            &-tag{
                margin-top: 15px;
                font-size: 12px;
                color: #00cca2;
            }
            .ant-list-item{
                border-bottom: none;
                display: block;
            }
        }
        &-left{
            height: calc(100vh - 123px);
            position: fixed;
            top: 77px;
            left: 195px;
            width: 300px;
            background: #fff;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            overflow-y: scroll;
        }
        &-add{
            position: fixed;
            bottom: 70px;
            right: 70px;
        }
    }
</style>

<style lang="scss">
    .labsRss{
        &-list{
            &-content{
                margin-top: 15px;
                img{
                    max-width: 100%;
                }
            }
        }
    }
</style>